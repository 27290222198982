<template>
  <v-container fluid ma-0 pa-0>
    <v-layout row wrap max-width-1400 full-width ma-auto>
      <v-flex xs12 py-5 text-xs-center>
        <p
          class="graphik-medium secondary--text my-3"
          :class="{'font-28': $vuetify.breakpoint.smAndUp, 'font-20': $vuetify.breakpoint.xsOnly}"
        >{{ $ml.get('general_payment_methods') }}</p>

        <p
          class="graphik-light my-3"
          :class="{'font-18': $vuetify.breakpoint.smAndUp, 'font-16': $vuetify.breakpoint.xsOnly}"
        >{{ $ml.get('content_payment_methods_description') }}</p>

        <v-layout row wrap align-center justify-space-between max-width-900 ma-auto my-3>
          <v-img
            v-for="(method, index) in payment_methods"
            :key="index"
            :aspect-ratio="1"
            contain
            position="center center"
            :src="method.icon"
            :lazy-src="method.icon"
            :max-width="$vuetify.breakpoint.xsOnly ? '60px' : '100px'"
            max-height="100px"
          ></v-img>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ContentPaymentMethods',
  data () {
    return {
      payment_methods: [
        {
          icon: require('@/assets/img/payments/visa.png')
        },
        {
          icon: require('@/assets/img/payments/mastercard.png')
        },
        {
          icon: require('@/assets/img/payments/american_express.png')
        },
        {
          icon: require('@/assets/img/payments/cash.png')
        }
      ]
    }
  }
}
</script>
